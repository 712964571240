import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled(Link)`
  padding: 14px 25px;
  border-radius: 60px;
  transform: translateY(0px);
  transition: transform 0.3s ease-out;
  ${(props) =>
    props.black &&
    css`
      color: #fff;
      background-color: #232125;
    `}
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 5px 10px 30px 4px #888888;
    `}
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.3s ease-out;
  }
`
