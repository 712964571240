import * as React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import { Error404 } from "../components/Error404"

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>Not Found</title>
        <meta
          name="description"
          content="OakGames - get amazing games for your company"
        />
      </Helmet>
      <Error404 />
    </Layout>
  )
}

export default NotFoundPage
