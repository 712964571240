import React from "react"
import { Container } from "./style"

export const Button = ({ text, shadow, black, to }) => {
  return (
    <Container target="_blank" to={to} black={black} shadow={shadow}>
      {text}
    </Container>
  )
}
