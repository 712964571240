import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  position: relative;
  padding: 0 100px 100px;
  margin: 0;
  height: calc(100vh + 250px);
  background: linear-gradient(-90deg, #a1c4fd 0%, #c2e9fb 100%);
  @media (max-width: 890px) {
    height: initial;
    padding: 90px 8vw 340px;
  }
`

export const LogoWrapper = styled(Link)`
  position: absolute;
  top: 50px;
  left: 100px;
  display: block;
  @media (max-width: 890px) {
    display: none;
  }
  ${(props) =>
    props.mobile &&
    css`
      display: none;
      @media (max-width: 890px) {
        top: 24px;
        left: 24px;
        display: block;
      }
    `}
`

export const ContactButton = styled.div`
  position: absolute;
  top: 58px;
  right: 100px;
  @media (max-width: 890px) {
    position: static;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  max-width: calc(1400px + 8vw);
  margin: 0 auto;
  height: 100vh;
  @media (max-width: 890px) {
    height: initial;
    gap: 40px;
    flex-direction: column;
  }
`

export const Left = styled.div`
  width: 600px;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  & h3 {
    text-align: center;
    margin-top: 28px;
  }
  @media (max-width: 890px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    & h1 {
      text-align: center;
    }
    & h3 {
      text-align: center;
      margin-top: 12px;
    }
  }
`
export const Buttons = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  @media (max-width: 890px) {
    display: none;
  }
`
export const Right = styled.div`
  max-width: 600px;
`
